require('./bootstrap');

import {mailer} from './mail.js';

let inputNumber = document.querySelectorAll('[type="number"]');

function validarCampo() {
    this.value = this.value.replace(/[a-z, ,/[`=`;'<>:"_&%#@!~'"".*\-?^${}()|[\]\\]/g, '');
}

if(inputNumber.length > 0) {
    inputNumber.forEach(input => {
        input.addEventListener('keyup', validarCampo)
        input.addEventListener('change', validarCampo)
    })
}


let data = {
    formElement: '.contact-form-1',
    to : ['ealtima@789.com.mx'],
    subject: 'Oportunidad laboral - ',     
    error: 'No se pudo enviar el correo',     
    success: 'Se envio el correo super bien',
    spanBlock: true,
    files: [
        {type: 'pdf',size: 2}
    ]
}

mailer(data, (response)=>{
    if(response.response.success){
        response.target.querySelector('.response').textContent = "Su información se envió correctamente."
    }else {
        response.target.querySelector('.response').textContent = "Algo salio mal, por favor intentalo de nuevo."
    }
})

let data1 = {
    formElement: '.contact-form',
    to : ['ealtima@789.com.mx'],
    subject: 'Contacto desde Etiquetas Altima',     
    error: 'No se pudo enviar el correo',     
    success: 'Se envio el correo super bien',
    spanBlock: true,
    files: [
        {type: 'pdf',size: 2}
    ]
}

mailer(data1, (response)=>{
    if(response.response.success){
        response.target.querySelector('.response').textContent = "Su información se envió correctamente."
    }else {
        response.target.querySelector('.response').textContent = "Algo salio mal, por favor intentalo de nuevo."
    }
})


//cards form
let form = document.querySelector('#open-form');

if(form){
    let cards = document.querySelectorAll('[data-postular]');
    if(cards.length > 0){
        cards.forEach(card => {
            card.addEventListener('click', function(){
                $(form).slideDown();
                form.querySelector('[name="Puesto"]').value = this.dataset.postular
            })
        });
    }
}